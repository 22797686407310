.bg-back {
  background-image: url(https://img.freepik.com/free-vector/realistic-style-technology-particle-background_23-2148426704.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.bg-back .card {
  background-color: transparent;
  backdrop-filter: blur(5px);
  border: 1px solid white !important;

  padding: 3rem !important;
  width: 40vw;
  box-shadow: 0 0 10px 0 white;
}

.active {
  color: red !important;
}

.pages {
  display: flex;
  justify-content: center;
  gap: 10px;
  list-style: none;
  margin-top: 32px;
}
.pages button {
  background-color: transparent;
  width: 32px;
}
.pages button:hover {
  background-color: blueviolet;
  color: white;
}

@media screen and (max-width: 700px) {
  .bg-back .card {
    width: 90%;
  }
}
